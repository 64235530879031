import XLSX from 'xlsx'
import dayjs from 'dayjs'

export function exportOrder (sheetData, fileName = dayjs().format('YYYY-MM')) {
  const arr = sheetData.reduce((list, value, index, rawList) => {
    const row = {
      创建时间: value.createTime,
      单号: value.orderId,
      产品名称: value.productName,
      下单数量: value.quantity,
      原材料名称: value?.materialFlow[0]?.requireName,
      原材料数量: value?.materialFlow[0]?.requireNum,
      印刷: value.printFlow,
      复合: value.reuniteFlow,
      分切: value.slittingFlow,
      制袋: value.bagMakingFlow,
      备注: value.remark
    }
    list.push(row)
    if (value.materialFlow.length) {
      value.materialFlow.forEach((foo, idx) => {
        if (idx > 0) {
          list.push({
            原材料名称: foo.requireName,
            原材料数量: foo.requireNum
          })
        }
      })
    }
    return list
  }, [])

  const sheet = XLSX.utils.json_to_sheet(arr)

  // 创建虚拟的workbook
  const wb = XLSX.utils.book_new()
  // 把sheet添加到workbook中
  XLSX.utils.book_append_sheet(wb, sheet, fileName)
  const workbookBlob = workbook2blob(wb)
  openDownload(workbookBlob, `${fileName}.xlsx`)

  function openDownload (blob, fileName = '123') {
    if (typeof blob === 'object' && blob instanceof Blob) {
      blob = URL.createObjectURL(blob) // 创建blob地址
    }
    const aLink = document.createElement('a')
    aLink.href = blob
    // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
    aLink.download = fileName || ''
    let event
    if (window.MouseEvent) event = new MouseEvent('click')
    //   移动端
    else {
      event = document.createEvent('MouseEvents')
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    }
    aLink.dispatchEvent(event)
  }

  function workbook2blob (workbook) {
  // 生成excel的配置项
    const wopts = {
      // 要生成的文件类型
      bookType: 'xlsx',
      // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      bookSST: false,
      type: 'binary'
    }
    const wbout = XLSX.write(workbook, wopts)
    // 将字符串转ArrayBuffer
    function s2ab (s) {
      const buf = new ArrayBuffer(s.length)
      const view = new Uint8Array(buf)
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
      return buf
    }
    const blob = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream'
    })
    return blob
  }
}
