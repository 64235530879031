
import XLSX from 'xlsx-style'

const exportExcel = function (detail) {
  const rawMaterial = detail.rawMaterial.map((item, index) => {
    const temp = index === 0 ? '复合胶辊：' + detail.complexRubberRoller : index === 1 ? '胶粘剂：' + detail.adhesive : ''
    return [
      '', item.name, item.require, item.specs, item.weight, '', temp
    ]
  })
  if (rawMaterial.length < 5) {
    const c = 5 - rawMaterial.length
    for (let i = 0; i < c; i++) {
      rawMaterial.push([])
    }
  }

  const data = [
    ['工艺单'],
    ['工单编号：', detail.orderId || '', '', '客户名称：', '', '制单日期：', detail.createTime || ''],
    ['产品名称：', detail.productName || ''],
    ['产品结构：', detail.productStructure || '', '', '厚度：' + detail.thickness || '', '成品规格：' + detail.productSpec || '', '数量:' + (detail.quantity || ''), '印刷量：'],
    ['原材料信息', '原材料名称及要求', '', '原料规格', '重量', '完成时间', '工艺要求：' + detail.technologicalRequirements || ''],
    ...rawMaterial,
    ['印刷油墨：', detail.printInk || ''],
    ['版号：', detail.editionNum || ''],
    ['印刷：', detail.print || ''],
    ['复合：', detail.reunite || '', '', '', '', '分切：', detail.slitting],
    ['袋型：', detail.bagType || '', '', '封口位置及尺寸：', detail.sealPositionSize || '', detail.formingPlate || '', detail.bagMakingRequirements || ''],
    ['包装要求', '编织袋' + (detail.PackagingRequirements.indexOf('编织袋') !== -1 ? '☑' : '□'), '', '纸箱' + (detail.PackagingRequirements.indexOf('纸箱') !== -1 ? '☑' : '□'), '套内带' + (detail.PackagingRequirements.indexOf('套内带') !== -1 ? '☑' : '□'), '合格证' + (detail.PackagingRequirements.indexOf('合格证') !== -1 ? '☑' : '□'), '下单人：' + '王园园'],
    ['材料：' + detail.materialFlow || '', '印刷：' + detail.printFlow || '', '', '复合：' + detail.reuniteFlow || '', '分切：' + detail.slittingFlow || '', '制袋：' + detail.bagMakingFlow || '']
  ]

  /* TODO: date1904 logic */
  function datenum (v, date1904) {
    if (date1904) v += 1462
    var epoch = Date.parse(v)
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000)
  }

  let printRow

  /* convert an array of arrays in JS to a CSF spreadsheet */
  function sheetFromArrayOfArrays (data, opts) {
    var ws = {}
    var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } }
    for (let R = 0; R !== data.length; ++R) {
      for (let C = 0; C !== data[R].length; ++C) {
        if (data[R][C] === '印刷油墨：') printRow = R
        if (range.s.r > R) range.s.r = R
        if (range.s.c > C) range.s.c = C
        if (range.e.r < R) range.e.r = R
        if (range.e.c < C) range.e.c = C
        var cell = { v: data[R][C] }
        if (cell.v == null) continue
        const cellRef = XLSX.utils.encode_cell({ c: C, r: R })

        /* TEST: proper cell types and value handling */
        if (typeof cell.v === 'number') cell.t = 'n'
        else if (typeof cell.v === 'boolean') cell.t = 'b'
        else if (cell.v instanceof Date) {
          cell.t = 'n'; cell.z = XLSX.SSF._table[14]
          cell.v = datenum(cell.v)
        } else cell.t = 's'

        // const style = {
        //   style: 'medium',
        //   color: '#333'
        // }
        cell.s = {
          alignment: { horizontal: 'center', vertical: 'center' }
          // border: {
          //   top: style,
          //   left: style,
          //   bottom: style,
          //   right: style·
          // }
        }
        ws[cellRef] = cell
      }
    }

    /* TEST: proper range */
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range)
    return ws
  }
  var ws = sheetFromArrayOfArrays(data)

  /* dummy workbook constructor */
  function Workbook () {
    if (!(this instanceof Workbook)) return new Workbook()
    this.SheetNames = []
    this.Sheets = {}
  }
  const wb = new Workbook()

  wb.SheetNames.push('Sheet')
  wb.Sheets.Sheet = ws
  wb.Sheets.Sheet['!merges'] = [
    {
      s: { c: 0, r: 0 }, // A1
      e: { c: 6, r: 0 } // G1
    },
    {
      s: { c: 1, r: 2 },
      e: { c: 6, r: 2 }
    },
    {
      s: { c: 0, r: 4 },
      e: { c: 0, r: 9 }
    },
    {
      s: { c: 1, r: printRow },
      e: { c: 6, r: printRow }
    },
    {
      s: { c: 1, r: printRow + 1 },
      e: { c: 6, r: printRow + 1 }
    },
    {
      s: { c: 1, r: printRow + 2 },
      e: { c: 6, r: printRow + 2 }
    }
  ]
  // wpx 字段表示以像素为单位，wch 字段表示以字符为单位
  wb.Sheets.Sheet['!cols'] = [
    { wch: 10 }, // 设置列宽为50字符
    { wch: 20 }, // 设置列宽为50字符
    { wch: 7 }, // 设置列宽为50字符
    { wch: 16 }, // 设置列宽为50字符
    { wch: 20 }, // 设置列宽为50字符
    { wch: 15 }, // 设置列宽为50字符
    { wch: 20 } // 设置列宽为50字符
  ]
  const a = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  // console.log(a)
  function s2ab (s) {
    var buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  }
  var blob = new Blob([s2ab(a)], { type: 'application/octet-stream' })

  function saveAs (obj, filename) {
    var link = document.createElement('a')
    link.download = filename
    link.href = URL.createObjectURL(obj)
    link.click()
    URL.revokeObjectURL(obj)
  }
  saveAs(blob, detail.orderId + '.xlsx')
}

export default exportExcel
