
const getStyle = function () {
  let str = ''
  const styles = document.querySelectorAll('style,link')
  for (let i = 0; i < styles.length; i++) {
    str += styles[i].outerHTML
  }
  return str
}

const toPrint = function (w, cb) {
  w.onload = function () {
    try {
      setTimeout(function () {
        w.focus()
        if (!w.document.execCommand('print', false, null)) {
          w.print()
        }
        w.close()
        cb && cb()
      })
    } catch (err) {
      console.log('err', err)
    }
  }
}

const writeIframe = function (content) {
  const iframe = document.createElement('iframe')
  const f = document.body.appendChild(iframe)
  iframe.id = 'myIframe'
  iframe.style = 'position:absolute;width:0;height:0;top:-10px;left:-10px;'
  const w = f.contentWindow || f.contentDocument
  const doc = f.contentDocument || f.contentWindow.document
  doc.open()
  doc.write(content)
  doc.close()
  toPrint(w, function () {
    document.body.removeChild(iframe)
  })
}
const printHtml = function (html) {
  return writeIframe(getStyle() + html)
}
export default printHtml
