import Request from '@/utils/request'

export function getTemplateListApi (data) {
  return Request({
    url: '/template/select',
    params: data
  })
}

// 列表
export function getOrderApi (data) {
  return Request({
    url: '/order/list',
    params: data
  })
}

// 创建订单
export function saveOrderApi (data) {
  return Request({
    url: '/order/create',
    method: 'post',
    data
  })
}

// 删除订单
export function delOrderApi (data) {
  return Request({
    url: '/order/del',
    method: 'post',
    data
  })
}

// 模板
export function getTemplateByIdApi (data) {
  return Request({
    url: '/template/item',
    params: data
  })
}

// 订单详情
export function getOrderDetailApi (data) {
  return Request({
    url: '/order/detail',
    params: data
  })
}

// 订单详情
export function changeStatusApi (data) {
  return Request({
    url: '/order/status',
    method: 'post',
    data
  })
}

// 获取订单原材料
export function getOrderMaterialApi (data) {
  return Request({
    url: '/order/material',
    params: data
  })
}

// 订单备注
export function orderRemarkApi (data) {
  return Request({
    url: '/order/remark',
    method: 'post',
    data
  })
}

// 获取原材料类别
export function getTypeSelectApi (data) {
  return Request({
    url: '/type/all'
  })
}

export function getSpecByTypeApi (data) {
  return Request({
    url: '/specsbytype',
    params: data
  })
}
