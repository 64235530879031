<template>
  <div v-loading.fullscreen.lock="loading">
    <el-dialog v-model="visible" :title="title" :close-on-click-modal="false"  :close-on-press-escape="false" width="1140px" class="add" top="40px">
      <el-form
        ref="form"
        :model="ruleForm"
        :rules="rules"
        class="form"
        label-width="140px"
      >
        <el-form-item label="选择订单模板" prop="templateId">
          <el-select
            v-model="ruleForm.templateId"
            filterable
            placeholder="请选择"
            @change="handleOrderChange"
            style="width: 500px;"
          >
            <el-option v-for="(item,index) in templateList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <div class="top">
          <el-form-item label="产品名称" prop="productName">
            <el-input
              disabled
              placeholder="请输入"
              v-model="ruleForm.productName"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品结构">
            <el-select v-model="ruleForm.productStructure" filterable clearable placeholder="请选择">
              <el-option v-for="(item,index) in PRODUCT_MIX" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="厚度">
            <el-input placeholder="请输入" v-model="ruleForm.thickness"></el-input>
          </el-form-item>
          <el-form-item label="成品规格">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.productSpec"
            ></el-input>
          </el-form-item>
          <el-form-item label="数量">
            <el-input placeholder="请输入" v-model="ruleForm.quantity"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="原材料信息" class="rawMaterial">
          <div class="raw_content">
            <div class="head">
              <span>工艺名称</span>
              <span>原料</span>
              <span>原料规格</span>
              <span>下单数量</span>
            </div>
            <div
              v-for="(item, index) in ruleForm.rawMaterial"
              :key="index"
              class="rawMaterial_item"
            >
              <el-form-item
                :prop="`rawMaterial.${index}.name`"
                :rules="rules.name"
              >
                <el-select v-model="item.name" filterable placeholder="请选择">
                  <el-option v-for="(item,index) in CRAFT" :key="index" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :prop="`rawMaterial.${index}.require`"
                :rules="rules.require"
              >
                <el-select v-model="item.require" filterable placeholder="请选择" @change="val => handleTypeChange(val,item,index)">
                  <el-option v-for="(item,index) in allType" :key="index" :label="item.type" :value="item._id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :prop="`rawMaterial.${index}.specs`"
                :rules="rules.specs"
              >
                <!-- <el-input v-model="item.specs" placeholder="请输入"></el-input> -->
                <el-select v-model="item.specs" filterable placeholder="请选择">
                  <el-option v-for="(item,index) in item.specsList" :key="index" :label="item.specs" :value="item._id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :prop="`rawMaterial.${index}.weight`"
                :rules="rules.weight"
              >
                <el-input v-model="item.weight" placeholder="请输入"></el-input>
              </el-form-item>
              <el-button
                v-if="index !== 0"
                type="text"
                class="btn btn-del"
                @click="deleteRawMaterial(index)"
              >删除</el-button
              >
            </div>
          </div>
          <el-button type="text" class="btn btn-add" @click="handleAdd"
          >添加</el-button
          >
        </el-form-item>
        <div class="bottom">
          <el-form-item label="工艺要求" prop="technologicalRequirements">
            <el-select v-model="ruleForm.technologicalRequirements" allow-create filterable placeholder="请选择">
              <el-option v-for="(item,index) in TECH_REQUIRES" :key="index" :value="item" :label="item"></el-option>
            </el-select>
            <!-- <el-input
              placeholder="请输入"
              v-model="ruleForm.technologicalRequirements"
            ></el-input> -->
          </el-form-item>
          <el-form-item label="复合胶辊" prop="complexRubberRoller">
            <el-select v-model="ruleForm.complexRubberRoller" allow-create filterable placeholder="请选择">
              <el-option v-for="(item,index) in COMPLEX_RUBBER_ROLLER" :key="index" :value="item" :label="item"></el-option>
            </el-select>
            <!-- <el-input
              placeholder="请输入"
              v-model="ruleForm.complexRubberRoller"
            ></el-input> -->
          </el-form-item>
          <el-form-item label="胶粘剂" prop="adhesive">
            <el-input placeholder="请输入" v-model="ruleForm.adhesive"></el-input>
          </el-form-item>
          <el-form-item label="印刷油墨">
            <el-input placeholder="请输入" v-model="ruleForm.printInk"></el-input>
          </el-form-item>
          <el-form-item label="版号" prop="editionNum">
            <el-input placeholder="请输入" v-model="ruleForm.editionNum"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="印刷" style="width: 978px;">
          <el-select v-model="ruleForm.print" filterable placeholder="请选择">
            <el-option v-for="(item,index) in PRINT" :key="index" :value="item" :label="item"></el-option>
          </el-select>
          <!-- <el-input placeholder="请输入" v-model="ruleForm.print"></el-input> -->
        </el-form-item>
        <div class="bottom">
          <el-form-item label="复合">
            <el-select v-model="ruleForm.reunite" filterable allow-create placeholder="请选择">
              <el-option v-for="(item,index) in REUNITE" :key="index" :value="item" :label="item"></el-option>
            </el-select>
            <!-- <el-input placeholder="请输入" v-model="ruleForm.reunite"></el-input> -->
          </el-form-item>
          <el-form-item label="分切" prop="slitting">
            <el-input placeholder="请输入" v-model="ruleForm.slitting"></el-input>
          </el-form-item>
          <el-form-item label="袋型" prop="bagType">
            <el-select v-model="ruleForm.bagType" filterable allow-create placeholder="请选择">
              <el-option v-for="(item,index) in BAG_TYPE" :key="index" :value="item" :label="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="封口位置及尺寸" prop="sealPositionSize">
            <el-select v-model="ruleForm.sealPositionSize" filterable allow-create placeholder="请选择">
              <el-option v-for="(item,index) in SEAL_POSITION_SIZE"  :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="成型板" prop="formingPlate">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.formingPlate"
            ></el-input>
          </el-form-item>
          <el-form-item label="制袋要求">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.bagMakingRequirements"
            ></el-input>
          </el-form-item>
          <el-form-item label="包装要求">
            <el-select
              v-model="ruleForm.PackagingRequirements"
              multiple
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option label="编织袋" value="编织袋"></el-option>
              <el-option label="纸箱" value="纸箱"></el-option>
              <el-option label="套内袋" value="套内袋"></el-option>
              <el-option label="合格证" value="合格证"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">创建</el-button>
      </template>
    </el-dialog>
  </div>

</template>

<script>
import _ from 'lodash'
import { ElMessage } from 'element-plus'
import { ref, computed, reactive, toRefs, onMounted, watch } from 'vue'
// import { useRoute } from 'vue-router'
// import * as math from 'mathjs'
import {
  SEAL_POSITION_SIZE,
  BAG_TYPE,
  CRAFT,
  RAW_MATERIAL,
  PRODUCT_MIX,
  TECH_REQUIRES,
  PRINT,
  REUNITE,
  COMPLEX_RUBBER_ROLLER
} from '@/utils/baseData'
import {
  saveOrderApi,
  getOrderItemApi,
  getTemplateByIdApi,
  getTemplateListApi,
  getSpecByTypeApi,
  getTypeSelectApi
} from './fetch'

export default {
  props: {
    id: [Number, String],
    refreshList: Function
  },
  setup (props, context) {
    const form = ref(null)

    const rawMaterialItem = {
      name: '',
      require: '',
      specs: '',
      weight: ''
    }
    const state = reactive({
      BAG_TYPE,
      SEAL_POSITION_SIZE,
      CRAFT,
      RAW_MATERIAL,
      PRODUCT_MIX,
      TECH_REQUIRES,
      REUNITE,
      PRINT,
      COMPLEX_RUBBER_ROLLER,
      templateList: [],
      visible: true,
      loading: false,
      allType: [],
      ruleForm: {
        // 产品名称
        productName: '',
        // 产品结构
        productStructure: '',
        // 厚度
        thickness: '',
        // 成品规格
        productSpec: '',
        // 数量
        quantity: '',
        // 印刷量
        printQuantity: '',
        // 原材料信息
        rawMaterial: [_.cloneDeep(rawMaterialItem)],
        // 工艺要求
        technologicalRequirements: '',
        // 复合胶辊
        complexRubberRoller: '',
        // 胶粘剂
        adhesive: '',
        // 印刷油墨
        printInk: '',
        // 版号
        editionNum: '',
        // 印刷
        print: '',
        // 复合
        reunite: '',
        // 分切
        slitting: '',
        // 袋型
        bagType: '',
        // 封口位置及尺寸
        sealPositionSize: '',
        // 成型板
        formingPlate: '',
        // 包装要求
        PackagingRequirements: [],
        // 制袋要求
        bagMakingRequirements: ''
      },

      rules: {
        templateId: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] }
        ],
        // 产品名称
        productName: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],

        // 工艺要求
        technologicalRequirements: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 复合胶辊
        complexRubberRoller: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 胶粘剂
        adhesive: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 版号
        editionNum: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 分切
        slitting: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 袋型
        bagType: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 封口位置及尺寸
        sealPositionSize: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 成型板
        formingPlate: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 工艺流程
        name: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 原料
        require: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 原料规格
        specs: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 重量
        weight: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ]
      }
    })

    watch(() => state.visible, (newVal) => {
      console.log(newVal)
      if (!newVal) {
        context.emit('close')
      }
    })

    onMounted(async () => {
      try {
        state.loading = true
        state.templateList = await getTemplateListApi()
        state.allType = await getTypeSelectApi()
        if (props.id) {
          const res = await getOrderItemApi({
            id: props.id
          })
          for (let i = 0; i < res.rawMaterial.length; i++) {
            const item = res.rawMaterial[i]
            console.log('object')
            item.specsList = await getSpecByTypeApi({
              typeId: item.require
            })
          }
          res.PackagingRequirements = res.PackagingRequirements && res.PackagingRequirements.split(',')
          state.ruleForm = res
        }
      } catch (e) {

      } finally {
        state.loading = false
      }
    })

    const title = computed(() => {
      return props.id ? '创建订单' : '创建订单'
    })

    const onSubmit = () => {
      form.value.validate(async (valid) => {
        if (valid) {
          try {
            const data = _.cloneDeep(state.ruleForm)
            if (data.PackagingRequirements) {
              data.PackagingRequirements =
              state.ruleForm.PackagingRequirements.join(',')
            }
            data.rawMaterial.forEach(bar => {
              delete bar.specsList
            })
            await saveOrderApi(data)
            state.visible = false
            props.refreshList()
            ElMessage({
              message: '保存成功！',
              type: 'success'
            })
          } catch (e) {
            console.dir(e)
            ElMessage({
              message: e.msg || e,
              type: 'error'
            })
          }
        } else {
          ElMessage({
            message: '请填写完整',
            type: 'error'
          })
        }
      })
    }

    const handleOrderChange = async id => {
      try {
        const res = await getTemplateByIdApi({ id })
        for (let i = 0; i < res.rawMaterial.length; i++) {
          const item = res.rawMaterial[i]
          item.specsList = await getSpecByTypeApi({
            typeId: item.require
          })
        }
        res.PackagingRequirements = res.PackagingRequirements && res.PackagingRequirements.split(',')
        state.ruleForm = {
          ...state.ruleForm,
          ...res
        }
      } catch (e) {
        this.$error(e)
      }
    }

    const handleAdd = () => {
      state.ruleForm.rawMaterial.push(_.cloneDeep(rawMaterialItem))
    }

    const deleteRawMaterial = (index) => {
      state.ruleForm.rawMaterial.splice(index, 1)
    }

    const handleTypeChange = async (val, item, index) => {
      item.specs = undefined
      item.specsList = await getSpecByTypeApi({
        typeId: val
      })
    }

    return {
      ...toRefs(state),
      form,
      title,
      onSubmit,
      handleAdd,
      deleteRawMaterial,
      handleOrderChange,
      handleTypeChange
    }
  }
}
</script>

<style lang="stylus" scoped>
.form
  .top,.bottom
    display flex
    flex-wrap: wrap
:deep(.rawMaterial)
  .raw_content
    width 900px
    border: 1px solid #eee;
    padding: 10px;
    .head
      display: flex;
      span
        display: inline-block;
        width: 210px;
    .rawMaterial_item
      display: flex;
      .el-form-item
        margin-right: 20px;
        .el-form-item__content
          width 190px
      .btn-del
        padding: 0;
        color: #F56C6C;
        height: 30px;

</style>
