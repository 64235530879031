<template>
  <div  class="dialog">
    <el-dialog
      v-loading='loading'
      v-model="dialog"
      title="订单详情"
      width="980px"
      :before-close="handleClose">
      <div v-if="state.orderInfo" class="content">
        <div class="item">
          <span class="label">订单号:</span>
          <span class="value">{{state.orderInfo.orderId}}</span>
        </div>
        <div class="line">
          <div class="item">
            <span class="label">产品名称:</span>
            <span class="value">{{state.orderInfo.productName}}</span>
          </div>
          <div class="item">
            <span class="label">产品结构:</span>
            <span class="value">{{state.orderInfo.productStructure}}</span>
          </div>
          <div class="item">
            <span class="label">厚度:</span>
            <span class="value">{{state.orderInfo.thickness}}</span>
          </div>
          <div class="item">
            <span class="label">成品规格:</span>
            <span class="value">{{state.orderInfo.productSpec}}</span>
          </div>
          <div class="item">
            <span class="label">数量:</span>
            <span class="value">{{state.orderInfo.quantity}}</span>
          </div>
        </div>
        <div class="rawItem">
          <span class="label">原材料信息:</span>
          <span class="value">
            <el-table :data="state.orderInfo.rawMaterial" >
              <el-table-column prop="name" label="工艺名称"></el-table-column>
              <el-table-column prop="require" label="原料"></el-table-column>
              <el-table-column prop="specs" label="原料规格"></el-table-column>
              <el-table-column prop="weight" label="重量"></el-table-column>
            </el-table>
          </span>
        </div>
        <div class="line">
          <div class="item">
            <span class="label">工艺要求:</span>
            <span class="value">{{state.orderInfo.technologicalRequirements}}</span>
          </div>
          <div class="item">
            <span class="label">复合胶辊:</span>
            <span class="value">{{state.orderInfo.complexRubberRoller}}</span>
          </div>
          <div class="item">
            <span class="label">胶粘剂:</span>
            <span class="value">{{state.orderInfo.adhesive}}</span>
          </div>
          <div class="item">
            <span class="label">印刷油墨:</span>
            <span class="value">{{state.orderInfo.printInk}}</span>
          </div>
          <div class="item">
            <span class="label">版号:</span>
            <span class="value">{{state.orderInfo.editionNum}}</span>
          </div>
          <div class="item">
            <span class="label">印刷:</span>
            <span class="value">{{state.orderInfo.print}}</span>
          </div>
          <div class="item">
            <span class="label">复合:</span>
            <span class="value">{{state.orderInfo.reunite}}</span>
          </div>
          <div class="item">
            <span class="label">分切:</span>
            <span class="value">{{state.orderInfo.slitting}}</span>
          </div>
          <div class="item">
            <span class="label">袋型:</span>
            <span class="value">{{state.orderInfo.bagType}}</span>
          </div>
          <div class="item">
            <span class="label">封口位置及尺寸:</span>
            <span class="value">{{state.orderInfo.sealPositionSize}}</span>
          </div>
          <div class="item">
            <span class="label">成型板:</span>
            <span class="value">{{state.orderInfo.formingPlate}}</span>
          </div>
          <div class="item">
            <span class="label">制袋要求:</span>
            <span class="value">{{state.orderInfo.PackagingRequirements}}</span>
          </div>
          <div class="item">
            <span class="label">包装要求:</span>
            <span class="value">{{state.orderInfo.bagMakingRequirements}}</span>
          </div>
        </div>
        <div class="foot">
          <div class="item material">
            <span class="label">材料:</span>
            <span class="value">
              <div class="material_item" v-for="(item,index) in state.orderInfo.materialFlow" :key="index">
                <span class="name">{{item.requireName}}:</span>
                <span class="num">{{item.requireNum}}</span>
              </div>
            </span>
          </div>
          <div class="item">
            <span class="label">印刷:</span>
            <span class="value">{{state.orderInfo.printFlow}}</span>
          </div>
          <div class="item">
            <span class="label">复合:</span>
            <span class="value">{{state.orderInfo.reuniteFlow}}</span>
          </div>
          <div class="item">
            <span class="label">分切:</span>
            <span class="value">{{state.orderInfo.slittingFlow}}</span>
          </div>
          <div class="item">
            <span class="label">制袋:</span>
            <span class="value">{{state.orderInfo.bagMakingFlow}}</span>
          </div>
        </div>
        <div class="remark">
          <span class="label">备注:</span>
          <span class="value">{{state.orderInfo.remark}}</span>
        </div>
      </div>
      <template #footer>
        <span v-if="state.orderInfo">订单状态：<span class="statusVal">{{statusMap[state.orderInfo.status]}}</span></span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, reactive, ref } from 'vue'
import {
  getOrderDetailApi
} from './fetch'

// dialog 是否展示
const dialog = ref(true)
// props
const props = defineProps({
  id: String
})
// emit事件
const emit = defineEmits(['close'])

const state = reactive({
  orderInfo: null,
  loading: false
})

const statusMap = {
  0: '已创建',
  2: '已印刷',
  3: '已复合',
  4: '已分切',
  5: '已制袋',
  6: '材料出库',
  10: '已完成'
}

onMounted(async () => {
  try {
    state.loading = true
    state.orderInfo = await getOrderDetailApi({
      id: props.id
    })
  } catch (e) {

  } finally {
    state.loading = false
  }
})

const handleClose = () => {
  emit('close')
}

</script>

<style lang="stylus" scoped>
.dialog
  :deep(.el-dialog__body)
    padding-bottom 5px
.content
  .line
    display flex
    flex-wrap wrap
  .item
    width 30%
    display flex
    .label
      color #7e7e7e
      flex-shrink 0
      display inline-block
      margin 0 15px 20px 0
      width 110px
      text-align right
    .value
      color #333
  .rawItem
    display flex
    margin-bottom 20px
    .label
      flex-shrink 0
      text-align right
      width 110px
      color #7e7e7e
      margin-right 15px
    .value
      display inline-block
      width 700px
      padding 10px
      border-radius 5px
      border 1px solid #eee
  .remark
    margin-top 20px
    margin-left 75px
    .value
      margin-left 20px
  .foot
    display flex
    .item
      flex 1
    .material
      .material_item
        margin-bottom 3px
        white-space: nowrap
        .name
          display inline-block
          width 70px
        .num
          display inline-block
          margin-left 15px

.statusVal
  color: #AAC84C
  font-size 18px
  font-weight bold

</style>
