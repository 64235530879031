
// 袋型
export const BAG_TYPE = [
  '中封插边袋',
  '中封不插边袋',
  '中封插边袋，不漏气',
  '中封不插边袋，不漏气',
  '三边封袋',
  '三边封袋，不漏气',
  '三边封拉链袋',
  '三边封拉链袋，不漏气',
  '自立袋，不漏气',
  '自立拉链袋，不漏气',
  '自立拉链袋',
  '其他',
  '无'
]

// 封口位置及尺寸
export const SEAL_POSITION_SIZE = [
  '封头5mm',
  '封头10mm',
  '封底5mm',
  '封底10mm',
  '宽烫3.5cm',
  '宽烫2.5cm',
  '宽烫4.5cm',
  '宽烫5cm',
  '宽烫6cm',
  '无'
]

// 工艺
export const CRAFT = [
  '印刷',
  '复合',
  '分切',
  '制袋'
]

// 原料
export const RAW_MATERIAL = [
  '哑光op',
  '光膜op',
  'pet',
  'pet铝',
  'pe',
  'cpp',
  'cpp高摩擦',
  '牛皮纸',
  '尼龙',
  '筒料pe',
  '纯铝',
  '筒料pe',
  '纸',
  'KOP'
]

// 产品结构
export const PRODUCT_MIX = [
  '光膜op/cpp',
  '光膜op/pe',
  '光膜op/pet/pe',
  '光膜op/pet铝/pe',
  '光膜op/pet/cpp',
  '光膜op/pet铝/cpp',
  '哑光op/cpp',
  '哑光op/pet/cpp',
  '哑光op/pet铝/cpp',
  '哑光op/pet铝/pe',
  '哑光op/pet/pe',
  '哑光op/cpp',
  'pet铝/cpp',
  'pet/pe',
  '光膜op/纯铝/pe',
  '光膜op',
  '筒料PE',
  '光膜op/pet、pet铝/pe',
  '哑光op/纸/pet铝/pe',
  'KOP/cpp高摩擦',
  '光膜op/pet、pet铝/CPP',
  'pet/pet铝/pe',
  'cpp',
  '哑光op/pet、pet铝/CPP',
  '尼龙+PE',
  '哑光op+cpp高摩擦',
  '光膜op+pet+cpp高摩擦',
  'pet+cpp'
]

// 印刷
export const PRINT = [
  '按上批。色相一致，印刷清晰，无甩墨刀线'
]

// 复合
export const REUNITE = [
  '无分层,无气泡',
  '无'
]

// 工艺要求
export const TECH_REQUIRES = [
  '普通级',
  '水煮级',
  '蒸煮级',
  '无'
]

// 复合胶辊
export const COMPLEX_RUBBER_ROLLER = [
  '212mm',
  '300mm',
  '305mm',
  '325mm',
  '341mm',
  '367mm',
  '370mm',
  '387mm',
  '402mm',
  '403mm',
  '405mm',
  '411mm',
  '418mm',
  '431mm',
  '435mm',
  '435mm',
  '450mm',
  '450mm',
  '451mm',
  '460mm',
  '465mm',
  '470mm',
  '480mm',
  '485mm',
  '506mm',
  '515mm',
  '525mm',
  '530mm',
  '530mm',
  '545mm',
  '547mm',
  '553mm',
  '562mm',
  '566mm',
  '573mm',
  '588mm',
  '605mm',
  '620mm',
  '630mm',
  '648mm',
  '669mm',
  '689mm',
  '737mm',
  '737mm'
]
