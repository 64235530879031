<template>
  <div v-loading="loading" class="template">
    <div class="top">
      <el-form class="filterForm" :model="filterForm" inline>
        <el-form-item label="产品名称" >
          <el-input v-model="filterForm.productName" clearable placeholder="全部"></el-input>
        </el-form-item>
        <el-form-item label="订单状态" >
          <el-select v-model="filterForm.status" clearable placeholder="全部">
            <el-option value="0" label="已创建"></el-option>
            <el-option value="2" label="已印刷"></el-option>
            <el-option value="3" label="已复合"></el-option>
            <el-option value="4" label="已分切"></el-option>
            <el-option value="5" label="已制袋"></el-option>
            <el-option value="6" label="材料已出库"></el-option>
            <el-option value="10" label="已完成"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button @click="handleAdd" type="primary">创建订单</el-button>
        <el-button @click="exportDialogShow = true">导出订单</el-button>
      </div>
    </div>
    <el-table :data="tableData" stripe>
      <el-table-column prop="orderId" label="订单号"  />
      <el-table-column prop="productName" label="产品名称"  />
      <el-table-column  label="状态" >
        <template #default="scope">
          {{statusMap[scope.row.status]}}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间"  />
      <el-table-column prop="remark" label="备注"  />
      <el-table-column label="操作" width="300px" >
        <template #default="scope">
          <el-button type="text" @click="handleView(scope.row._id)">查看</el-button>
          <el-button type="text" @click="handleRemark(scope.row._id,scope.row.remark)">备注</el-button>
          <el-button type="text" @click="handlePrint(scope.row._id)" style="color:#67C23A">打印</el-button>
          <el-button type="text" @click="changeStatus(scope.row._id,2)">印刷</el-button>
          <el-button type="text" @click="changeStatus(scope.row._id,3)">复合</el-button>
          <el-button type="text" @click="changeStatus(scope.row._id,4)">分切</el-button>
          <el-button type="text" @click="changeStatus(scope.row._id,5)">制袋</el-button>
          <el-button type="text" @click="changeStatus(scope.row._id,6)">材料出库</el-button>
          <el-button type="text" @click="changeStatus(scope.row._id,10)">完成订单</el-button>
          <!-- <el-button type="text" v-if="[0,10].includes(scope.row.status)" @click="handleExportExcel(scope.row._id,10)">导出Excel</el-button> -->
          <el-button type="text" @click="delOrder(scope.row._id)" style="color:#F56C6C;">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:current-page="currentPage"
      :page-size="20"
      layout="total, prev, pager, next"
      @current-change="handleCurrentChange"
      :total="total"
    />
    <ExportDialog v-if="exportDialogShow" @close="exportDialogShow = false"></ExportDialog>
    <EditDialog v-if="editDialogShow" :id="currentId" :refresh-list="getList" @close="handleDialogClose"/>
    <ViewDrawer v-if="viewShow" :id="viewId"  @close="handleViewClose"/>
    <MaterialDialog v-if="materialDialogShow" :id="currentId" :refreshList="getList" @close="handleDialogClose"></MaterialDialog>
    <!-- 打印用模板 -->
    <div class="noDisplay" style="display: none;">
      <div ref="printTemplate" v-if="current" class="orderPrintTemplate">
        <el-row>
          <el-col class="title">工艺单</el-col>
        </el-row>
        <el-row>
          <el-col :span='3'>工单编号：</el-col>
          <el-col :span="21" class="noBorder">
            <el-row>
              <el-col :span='6'>{{current.orderId || ''}}</el-col>
              <el-col :span='10'>客户名称：</el-col>
              <el-col :span='3'>制单日期：</el-col>
              <el-col :span='5'>{{current.createTime || ''}}</el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='3'>产品名称：</el-col>
          <el-col :span='21' class="productName">{{current.productName || ''}}</el-col>
        </el-row>
        <el-row>
          <el-col :span='3'>产品结构：</el-col>
          <el-col :span="21" class="noBorder">
            <el-row>
              <el-col :span='6'>{{current.productStructure || ''}}</el-col>
              <el-col :span='4'>厚度：{{current.thickness || ''}}</el-col>
              <el-col :span='6'>成品规格：{{current.productSpec || ''}}</el-col>
              <el-col :span='3'>数量：{{current.quantity || ''}}</el-col>
              <el-col :span='5'>印刷量：</el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='3'>
            <div class="alignCenter">原材料信息</div>
          </el-col>
          <el-col :span='21' class="noBorder">
            <el-row>
              <el-col :span="6">原材料名称及要求</el-col>
              <el-col :span="4">原料规格</el-col>
              <el-col :span="6">重量</el-col>
              <el-col :span="3">完成时间</el-col>
              <el-col :span="5">工艺要求：{{current.technologicalRequirements || ''}}</el-col>
            </el-row>
            <el-row>
              <el-col :span="3">{{current.rawMaterial[0]?.name}}</el-col>
              <el-col :span="3">{{current.rawMaterial[0]?.require}}</el-col>
              <el-col :span="4">{{current.rawMaterial[0]?.specs}}</el-col>
              <el-col :span="6">{{current.rawMaterial[0]?.weight}}</el-col>
              <el-col :span="3"></el-col>
              <el-col :span="5">复合胶辊：{{current.complexRubberRoller}}</el-col>
            </el-row>
            <el-row>
              <el-col :span="3">{{current.rawMaterial[1]?.name}}</el-col>
              <el-col :span="3">{{current.rawMaterial[1]?.require}}</el-col>
              <el-col :span="4">{{current.rawMaterial[1]?.specs}}</el-col>
              <el-col :span="6">{{current.rawMaterial[1]?.weight}}</el-col>
              <el-col :span="3"></el-col>
              <el-col :span="5">胶粘剂：{{current.adhesive}}</el-col>
            </el-row>
            <el-row>
              <el-col :span="3">{{current.rawMaterial[2]?.name}}</el-col>
              <el-col :span="3">{{current.rawMaterial[2]?.require}}</el-col>
              <el-col :span="4">{{current.rawMaterial[2]?.specs}}</el-col>
              <el-col :span="6">{{current.rawMaterial[2]?.weight}}</el-col>
              <el-col :span="3"></el-col>
              <el-col :span="5"></el-col>
            </el-row>
            <el-row>
              <el-col :span="3">{{current.rawMaterial[3]?.name}}</el-col>
              <el-col :span="3">{{current.rawMaterial[3]?.require}}</el-col>
              <el-col :span="4">{{current.rawMaterial[3]?.specs}}</el-col>
              <el-col :span="6">{{current.rawMaterial[3]?.weight}}</el-col>
              <el-col :span="3"></el-col>
              <el-col :span="5"></el-col>
            </el-row>
            <el-row>
              <el-col :span="3">{{current.rawMaterial[4]?.name}}</el-col>
              <el-col :span="3">{{current.rawMaterial[4]?.require}}</el-col>
              <el-col :span="4">{{current.rawMaterial[4]?.specs}}</el-col>
              <el-col :span="6">{{current.rawMaterial[4]?.weight}}</el-col>
              <el-col :span="3"></el-col>
              <el-col :span="5"></el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='3'>印刷油墨：</el-col>
          <el-col :span='21'>{{current.printInk || ''}}</el-col>
        </el-row>
        <el-row>
          <el-col :span='3'>版号：</el-col>
          <el-col :span='21'>{{current.editionNum || ''}}</el-col>
        </el-row>
        <el-row>
          <el-col :span='3'>印刷：</el-col>
          <el-col :span='21'>{{current.print || ''}}</el-col>
        </el-row>
        <el-row>
          <el-col :span='3'>复合：</el-col>
          <el-col :span="21" class="noBorder">
            <el-row>
              <el-col :span='10'>{{current.reunite || ''}}</el-col>
              <el-col :span='6'>分切：</el-col>
              <el-col :span='8'>{{current.slitting || ''}}</el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">袋型：</el-col>
          <el-col :span="21" class="noBorder">
            <el-row>
              <el-col :span="6">{{current.bagType||''}}</el-col>
              <el-col :span="4">封口位置及尺寸：</el-col>
              <el-col :span="6">{{current.sealPositionSize||''}}</el-col>
              <el-col :span="3">{{current.formingPlate||''}}</el-col>
              <el-col :span="5">{{current.bagMakingRequirements||''}}</el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">包装要求</el-col>
          <el-col :span="21" class="noBorder">
            <el-row>
              <el-col :span="6">{{ '编织袋' + (current.PackagingRequirements.indexOf('编织袋') !== -1 ? '☑' : '□') }}</el-col>
              <el-col :span="4">{{'纸箱' + (current.PackagingRequirements.indexOf('纸箱') !== -1 ? '☑' : '□')}}</el-col>
              <el-col :span="6">{{'套内带' + (current.PackagingRequirements.indexOf('套内带') !== -1 ? '☑' : '□')}}</el-col>
              <el-col :span="3">{{ '合格证' + (current.PackagingRequirements.indexOf('合格证') !== -1 ? '☑' : '□') }}</el-col>
              <el-col :span="5">下单人：WYY</el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">材料：</el-col>
          <el-col :span="21" class="noBorder">
            <el-row>
              <el-col :span="6">印刷：</el-col>
              <el-col :span="4">复合：</el-col>
              <el-col :span="6">分切：</el-col>
              <el-col :span="8">制袋：</el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, inject, nextTick } from 'vue'
import EditDialog from './edit.vue'
import ExportDialog from './exportDialog.vue'
import ViewDrawer from './view.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import printHtml from '@/utils/print'
import exportExcel from '@/utils/exportExcel'
import {
  getOrderApi,
  changeStatusApi,
  getOrderDetailApi,
  delOrderApi,
  orderRemarkApi
} from './fetch'
import MaterialDialog from './materialDialog.vue'
export default {
  name: 'TemplatePage',
  components: {
    EditDialog,
    ViewDrawer,
    MaterialDialog,
    ExportDialog
  },
  setup () {
    const $msg = inject('$msg')
    const printTemplate = ref(null)
    const state = reactive({
      exportDialogShow: false,
      current: null,
      tableData: [],
      filterForm: {
        productName: '',
        status: undefined
      },
      currentPage: 1,
      total: 0,
      loading: false,
      editDialogShow: false,
      materialDialogShow: false,
      currentId: undefined,
      viewId: undefined,
      viewShow: false,
      statusMap: {
        0: '已创建',
        6: '材料已出库',
        2: '已印刷',
        3: '已复合',
        4: '已分切',
        5: '已制袋',
        10: '已完成'
      },
      statusMap2: {
        0: '已创建',
        6: '材料出库',
        2: '印刷',
        3: '复合',
        4: '分切',
        5: '制袋'
      }
    })

    onMounted(async () => {
      await getList()
    })

    const getList = async () => {
      try {
        state.loading = true
        const res = await getOrderApi({
          page: state.currentPage,
          rows: 20,
          ...state.filterForm
        })
        state.tableData = res.data
        state.total = res.total
      } catch (e) {
        $msg({
          message: e,
          type: 'error'
        })
      } finally {
        state.loading = false
      }
    }

    const handleEdit = (id) => {
      state.currentId = id
      state.editDialogShow = true
    }

    const handleAdd = () => {
      state.editDialogShow = true
    }
    const handleView = id => {
      console.log(id)
      state.viewShow = true
      state.viewId = id
    }

    const handleDialogClose = () => {
      state.editDialogShow = false
      state.materialDialogShow = false
      state.currentId = undefined
    }
    const handleViewClose = () => {
      state.viewShow = false
    }

    const changeStatus = async (id, status) => {
      console.log(status)
      if (status !== 10) {
        if (status === 6) {
          state.currentId = id
          state.materialDialogShow = true
        } else {
          ElMessageBox.prompt('', state.statusMap2[status], {
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            inputValidator: (val) => {
              return !!val
            },
            inputErrorMessage: '请输入'
          })
            .then(async ({ value }) => {
              console.log(value)
              await changeStatusApi({
                tips: value,
                id,
                status
              })
              $msg({
                message: state.statusMap[status],
                type: 'success'
              })
              await getList()
            })
            .catch((e) => {
              if (e !== 'cancel') {
                ElMessage({
                  type: 'error',
                  message: e
                })
              }
            })
        }
      } else {
        await changeStatusApi({
          id,
          status
        })
        $msg({
          message: state.statusMap[status],
          type: 'success'
        })
        await getList()
      }
    }

    // 导出excel
    const handleExportExcel = async id => {
      const detail = await getOrderDetailApi({ id })
      exportExcel(detail)
    }

    // 打印
    const handlePrint = async id => {
      const detail = await getOrderDetailApi({ id })
      state.current = detail
      await nextTick()
      printHtml(printTemplate.value.outerHTML)
    }

    const delOrder = async id => {
      ElMessageBox.confirm(
        '确定删除该订单吗？',
        '删除',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(async () => {
          await delOrderApi({
            id
          })
          $msg({
            message: '删除成功！',
            type: 'success'
          })
          await getList()
        })
        .catch((e) => {
          if (e !== 'cancel') {
            ElMessage({
              type: 'error',
              message: e.msg || e
            })
          }
        })
    }

    const handleCurrentChange = async () => {
      try {
        await getList()
      } catch (e) {
        ElMessage({
          type: 'error',
          message: e
        })
      }
    }

    // 订单备注
    const handleRemark = (id, remark) => {
      ElMessageBox.prompt('', '备注', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        inputType: 'textarea',
        inputPlaceholder: '请输入备注',
        inputValue: remark,
        inputValidator: (val) => {
          return !!val
        },
        inputErrorMessage: '请输入'
      })
        .then(async ({ value }) => {
          await orderRemarkApi({
            remark: value,
            id
          })
          $msg({
            message: '保存成功！',
            type: 'success'
          })
          await getList()
        })
        .catch((e) => {
          if (e !== 'cancel') {
            ElMessage({
              type: 'error',
              message: e
            })
          }
        })
    }

    const handleSearch = () => {
      getList()
    }

    return {
      ...toRefs(state),
      printTemplate,
      getList,
      handleAdd,
      handleDialogClose,
      handleEdit,
      handleView,
      handleViewClose,
      changeStatus,
      handleExportExcel,
      handlePrint,
      delOrder,
      handleCurrentChange,
      handleRemark,
      handleSearch
    }
  }
}
</script>

<style lang="stylus" scoped>
.template
  .top
    display flex
    justify-content space-between
.orderPrintTemplate
  width 750px
  border-top 1px solid #000
  border-left 1px solid #000
  .el-col
    box-sizing: border-box
    border-right 1px solid #000
    border-bottom 1px solid #000
    min-height: 20px
    padding 0 5px
    font-size 14px
    .alignCenter
      line-height 120px
    &.title
      font-size 20px
      text-align center
    &.productName
      color red
      font-size 16px
      text-align center
    &.noBorder
      border none
      margin 0
      padding 0
</style>
