<template>
  <el-dialog v-loading="loading" v-model="visible" title="材料出库" :close-on-click-modal="false" :close-on-press-escape="false" class="add">
    <el-form
      ref="form"
      :model="ruleForm"
      :rules="rules"
      class="form"
      label-width="140px"
    >
      <div class="material_item" v-for="(item,index) in ruleForm.materialList" :key="index">
        <el-form-item :label="item.requireName" :prop="`materialList.${index}.requireNum`" :rules="rules.requireNum">
          <el-input v-model="item.requireNum" placeholder="请输入出库数量"></el-input>
        </el-form-item>
      </div>

    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import { ref, reactive, toRefs, inject, onMounted } from 'vue'

import {
  changeStatusApi,
  getOrderMaterialApi
} from './fetch'

export default {
  props: {
    id: [Number, String],
    refreshList: Function
  },
  setup (props) {
    const $msg = inject('$msg')
    const form = ref(null)

    const state = reactive({
      visible: true,
      loading: false,
      ruleForm: {
        materialList: []
      },
      rules: {
        requireNum: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ]
      }
    })

    onMounted(async () => {
      try {
        state.loading = true
        state.ruleForm.materialList = await getOrderMaterialApi({
          id: props.id
        })
      } catch (e) {

      } finally {
        state.loading = false
      }
    })

    const onSubmit = () => {
      form.value.validate(async (valid) => {
        if (valid) {
          try {
            await changeStatusApi({
              tips: state.ruleForm.materialList,
              id: props.id,
              status: 6
            })
            $msg({
              message: '材料出库成功！',
              type: 'success'
            })
            state.visible = false
            await props.refreshList()
          } catch (e) {
            console.dir(e)
            ElMessage({
              message: e.msg || e,
              type: 'error'
            })
          }
        } else {
          ElMessage({
            message: '请填写完整',
            type: 'error'
          })
        }
      })
    }

    return {
      ...toRefs(state),
      form,
      onSubmit
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
